import React, { useMemo, useEffect, Suspense, useState, useRef } from 'react'
import classes from "./DevicesList.module.css";
import ListCard from './ListCard';
import BasicTable from '../Table/Table/Table';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons';
import UseDateFormat from '../hooks/UseDateFormat';
import Pill_Widget from '../UI/Widgets/Pill_Widget';
import DropdownList from '../UI/Cards/DropdownList';
import SettingsCog from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg"
import SuspendDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import UnSuspendDeviceIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Unlocked.svg"
import ClearLogsIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import ChangeOwnerIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Change User.svg"
import ChangeCamectIntergrationTypeIcon_Off from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - Off.svg";
import ChangeCamectIntergrationTypeIcon_On from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - On.svg";
import CopyDeviceSettingsIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Logs.svg";
import ChangeTagIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg'
import RebootDeviceIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__OffButton.svg'
import ResetDeviceIcon from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Refresh.svg'
import { useDispatch, useSelector } from 'react-redux';
import { changeCamectIntegration, clearDeviceLogs, copyIPIOSettings, getDevices, rebootIPIODevice, resetIPIODevice, suspendDevice } from '../../Api Methods/Api';
import { serviceActions } from '../../store/service-Slice';
import useSetCookie from '../hooks/UseSetCookie';
import Modal from '../Modal/Modal';
import DropDownForm from '../forms/DropDownForm';
import InputForm from '../forms/InputForm';
import DataManager from '../../hooks/DataManager';
import GridPaginationControls from '../Table/Table/GridPaginationControls';
import OutputPinCircle from '../UI/DataLayouts/OutputPinCircle';
import InputPinCircle from '../UI/DataLayouts/InputPinCircle';
import useParseArrayDeviceData from '../hooks/useParseArrayDeviceData';
import SetTag from '../Modal/ModalLayouts/SetTag';
import { uiActions } from '../../store/ui-slice';
import Button1 from '../UI/Button/Button1';

const DevicesList = ({ devices, filtering, HandleSaveSearchQuery, totalPages, setTotalPages, pageFetchedIndex, setPageFetchedIndex, pageSize, setPageSize, totalDevices, setTotalDevices, isUsersSites, groupMode, update, setUpdate  }) => {
    const [SiteDevices, setSiteDevices] = useState(devices)
    console.log(SiteDevices)
    const token = useSelector(state => state.auth.token);
    const client = useSelector(state => state.auth.client);
    const dataManager = new DataManager();
    const parsedData = useParseArrayDeviceData(devices);

    const apiData = {
      token,
      client
    }

    const HandleNextPage = () => {
        setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex + 1)
        dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex + 1)
    }

    const HandlePreviousPage = () => {
        setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex - 1)
        dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex - 1)
    }

    const HandleSetPageIndex = (pageFetchedIndex) => {
        setPageFetchedIndex(pageFetchedIndex)
    }

    const HandlePageSize = (PageSize) => {
        setPageSize(PageSize);
        dataManager.setItem('pageSize', PageSize)
        HandlePageCount(totalPages);
        dataManager.setItem('totalPages', totalPages)
    }

    const HandlePageCount = (totalPages) => {
        setTotalPages(totalPages);
    }

    useEffect(() => {
        setSiteDevices(devices);
        dataManager.setItem('pageIndex', pageFetchedIndex)
    }, [pageFetchedIndex, pageSize, devices])
  
    const HandleChangeOwner = async (result) => {
        const data = await devices;
        const updatedData = data.map(row => {
        if (row.uuid === result.DeviceUUID) {
            return { ...row, name: result.dropDown };
        }
        return row;
        });

        setSiteDevices(updatedData);
    };

    const companyName = useSelector(state => state.auth.companyName);
    const userID = useSelector(state => state.auth.userID);
    const DeviceKey = sessionStorage.getItem("DeviceKey")

    // -- Controls the settingCog wheel -------------------------------------- 
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const handleSettingsClick = (rowId) => {
        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
        setOpenDropdownRowId(null);
        } else {
        // Otherwise, open the clicked dropdown
        setOpenDropdownRowId(rowId);
        }
    };
  // -----------------------------------------------------------------------


  //------------------ handling the closing of the setting cog wheel drop down by clicking else where -----------------------------------

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // Clicked outside the dropdown menu, so close it
            setOpenDropdownRowId(null);
        }
        };

    // Add event listener to listen for clicks outside the dropdown menu
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Cleanup: Remove event listener when component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //------------------------------------------------------------------------

  //------------------------------- Modal Logic  ----------------------------------------------

  const ModalDialog = useRef();

  const HandleShowModal = () => {
    ModalDialog.current.open();
  }

  const HandleCloseModal = () => {
    ModalDialog.current.close();
  }

  const [modalTitle, setModalTitle] = useState("title")
  const [modalComponent, setModalComponent] = useState()
  //---------------------------------------------------------------------------------------------

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleUipiodOnClick = (DeviceUUID) => {

    dispatch(serviceActions.setDeviceUUID(DeviceUUID));

    useSetCookie("DeviceUUID", DeviceUUID, 1);

    navigate("/Dashboard/SiteView/" + DeviceUUID);
  }

  // ---------- carrys out clicked action in dropdown
  const UserActionHandler = (userAction, suspend, DeviceId, DeviceUUID, userID, DeviceName, DeviceKey, camectIntegationType, tag, row, DeviceDetails) => {
    console.log(camectIntegationType)
    switch (userAction) {
        case 'Unsuspend Site':
        case 'Suspend Site':
            const SuspendDevice = async () => {
                const response = await suspendDevice(apiData, suspend, DeviceId, dispatch);
                if (response) {
                  let status;

                  if (row.status === "suspended"){
                    status = "available"
                  } else {
                    status = "suspended"
                  }
                    setSiteDevices((prevSiteDevices) =>
                      prevSiteDevices.map((device) =>
                          device.uuid === DeviceUUID ? { ...device, status: status } : device
                      )
                  );

                  setOpenDropdownRowId(null);
                  setUpdate(prevUpdate => prevUpdate + 1);
                }
            }
            SuspendDevice();
            break;
        case 'Clear Logs':
            const ClearLogs = () => {
                setOpenDropdownRowId(null);

                const HandleClearLogs = async () => {
                    const response = await clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
                    if (response) {
                        HandleCloseModal();
                    }
                }

                const Cancel = () => {
                    HandleCloseModal();
                }

                setModalTitle("Clear Logs");
                setModalComponent(
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
                        <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                            <h4 style={{ margin: "0px" }}>{"Are you sure you want to clear Device: " + DeviceKey + " Log's. This action is "}</h4>
                            <h4 style={{ margin: "0px", color: "red" }}>Permanent</h4>
                            <h4 style={{ margin: "0px" }}>!</h4>
                        </div>
                        <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                            <Button1 propsclassName={classes.CancelButton} onClick={Cancel}>Cancel</Button1>
                            <Button1 propsclassName={classes.ClearLogs} onClick={() => HandleClearLogs()}>Clear Logs</Button1>
                        </div>
                    </div>
                )
                HandleShowModal()
            }
            ClearLogs();
            break;
        case 'Change Tag':
            const ChangeTag = async () => {
                setOpenDropdownRowId(null);
                setModalTitle("Change Tag")
                setModalComponent(<SetTag placeholder={tag} DeviceUUID={DeviceUUID} HandleCloseModal={HandleCloseModal} dispatch={dispatch} update={update} setUpdate={setUpdate} SiteDevices={SiteDevices} setSiteDevices={setSiteDevices}  />)
                HandleShowModal()
            }
            ChangeTag();
            break;
        case 'Change Owner':
            const ChangeOwner = async (DeviceKey) => {
                setModalTitle("Change owner of: " + DeviceKey)
                setModalComponent(<DropDownForm apiData={apiData} DeviceUUID={DeviceUUID} companyName={""} NotificationTitle={""} label={"Please select a new device owner below"} dropDownOptions={['IPIO', 'PARFAS', 'CPS']} HandleCloseModal={HandleCloseModal} update={update} setUpdate={setUpdate} SiteDevices={SiteDevices} setSiteDevices={setSiteDevices} />)
                setOpenDropdownRowId(null);
                HandleShowModal()
            }
            ChangeOwner(DeviceKey);
            break;
        // case 'Change Camect Integration Type':
        //     const Changecamectintergrationtype = async () => {
        //         const CamectIntegationType = camectIntegationType == 2 ? 1 : 2;
        //         const notificationMessage = CamectIntegationType == 1 ? "Whole Camect" : "Per Cam Arming"
        //         const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName, dispatch)
        //         if (response) {
        //             dispatch(
        //                 uiActions.renderNotification({
        //                     status: 'successful',
        //                     title: tag || DeviceKey,
        //                     message: "Camect integration changed to " + notificationMessage,
        //                 })
        //             );
                   
        //             DeviceDetails.camectIntegrationType = CamectIntegationType;

        //             console.log(DeviceDetails);
        //             row.deviceDetails = JSON.stringify(DeviceDetails);
        //             setOpenDropdownRowId(null);
        //         }
        //     }
        //     Changecamectintergrationtype();
        //     break;
        case 'Copy site settings':
            const Copydevicesettings = async (apiData, fromDeviceUUID) => {
                setModalTitle("Copy settings of: " + DeviceKey)
                setModalComponent(
                    <div>
                        <InputForm label={"Enter site UIPIOID to copy settings to"} submitButtonText={"Copy"} processFunction={copyIPIOSettings} processFunctionParamaters={{ apiData, fromDeviceUUID }} HandleCloseModal={setOpenDropdownRowId} dispatch={dispatch} />
                    </div>
                )
                setOpenDropdownRowId(null);
                HandleShowModal()
            }
            Copydevicesettings(apiData, DeviceUUID);
            break;
        case 'Reboot Site':
            const RebootDevice = async () => {
                rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName);
                setOpenDropdownRowId(null);
            }
            RebootDevice();
            break;
        case 'Reset Site':
            const ResetDevice = async () => {
                const response = resetIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch)
                if (response) {
                    dispatch(
                        uiActions.renderNotification({
                            status: 'successful',
                            title: tag || DeviceKey,
                            message: "Site reset successfully",
                        })
                    );
                }
                setOpenDropdownRowId(null);
            }
            ResetDevice();
        default:
    }
}

  const outputs =
  {
      output1: 1,
      output2: 0,
      output3: 0,
      output4: 1
  };

  const inputs =
  {
      input1: 1,
      input2: 1,
      input3: 1,
      input4: 1,
      input5: 0,
      input6: 1,
      input7: 1,
      input8: 0,
  };

  const tablecolumns = [
    {
      header: 'Tag',
      accessorKey: 'tag',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.tag}
          </div>
        )
      }
    },
    {
      header: 'UIPIOID',
      accessorKey: 'key',
      cell: (row) => {

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", fontWeight:"500" }}>
            {row.row.original.key}
          </div>
        )
      }
    },
    {
      header: 'Serial Number',
      accessorKey: 'subGroup',
      cell: (row) => {
        const subGroup = row.row.original.subGroup;
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {subGroup != "" ? subGroup : "-"}
          </div>
        )
      }
    },
    {
      header: 'IP Address',
      accessorKey: 'ip',
      cell: (row) => {
        const isCC = row.row.original.type === "CC" ? true : false
        console.log(isCC)
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            <Pill_Widget boolean={isCC} PillColor_IfTrue={"#6996f1"} PillColor_IfFalse={"#626a67"} text_IfTrue={"IPIO CC"} text_IfFalse={row.row.original.ip}/>
            
          </div>
        )
      }
    },
    {
      header: 'Firmware',
      accessorKey: 'firmware',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {row.row.original.firmware}
          </div>
        )
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row) => {
        const OnlineStatus = row.row.original.online == "YES" ? true : false
        const SuspendedStatus = row.row.original.status == "suspended";
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", display: "flex", gap: "5px" }}>
            <Pill_Widget boolean={OnlineStatus} PillColor_IfTrue={"#22ca80"} PillColor_IfFalse={"#ff4f70"} text_IfTrue={"Online"} text_IfFalse={"Offline"} />
            {SuspendedStatus && < Pill_Widget boolean={SuspendedStatus} PillColor_IfTrue={"black"} PillColor_IfFalse={"none"} text_IfTrue={"Suspended"} />}
          </div>
        )
      }
    },
    {
      header: 'Initial Activation',
      accessorKey: 'created',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content" }}>
            {UseDateFormat(row.row.original.created)}
          </div>
        )
      }
    },
    {
      header: 'Users',
      accessorKey: 'userCount',
      cell: (row) => {
        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", fontWeight:"500"}}>
            {row.row.original.userCount}
          </div>
        )
      }
    },
    {
        header: 'States',
        cell: (row) => {
            const data = parsedData[row.row.index]
            console.log(row)
            console.log(data)
          return (
            <div className={classes["bottom-wrapper"]}>
                <div>
                    <ul className={classes.outputs}>
                        <OutputPinCircle outputs={outputs} OutputPins={data.OutputPins} />
                    </ul>
                </div>
                <div className={classes["bottom-wrapper_divider"]} />
                <div>
                    <ul className={classes.inputs}>
                        <InputPinCircle inputs={inputs} InputPins={data.InputPins} />
                    </ul>
                </div>
            </div>
          )
        }
    },
    {
      header: 'Owned by',
      accessorKey: 'name',
      cell: (row) => {
        let companyName = row.row.original.name;

        const companyColors = {
            EyeSpyFX: "rgb(100, 149, 237)",
            IPIO: "rgb(100, 149, 237)",
            PARFAS: "rgb(0, 0, 0)",
            CPS: "rgb(8, 155, 121)"
          };
          
          const companyColor = companyColors[companyName] || "#6e6e6e";

        return (
          <div onClick={() => HandleUipiodOnClick(row.row.original.uuid)} style={{ cursor: "pointer", width: "fit-content", color: "white", fontWeight:"600"}}>
            <Pill_Widget boolean={true} PillColor_IfTrue={companyColor} PillColor_IfFalse={"#626a67"} text_IfTrue={row.row.original.name}/>
          </div>
        )
      }
    },
    {
      id: 'actions',
      cell: (row) => {
        let items = [
            {
              image:
                  row.row.original.status !== "suspended"
                      ? SuspendDeviceIcon
                      : UnSuspendDeviceIcon,
              title:
                  row.row.original.status !== "suspended"
                      ? "Suspend Site"
                      : "Unsuspend Site",
            },
            {
                image: ClearLogsIcon,
                title: "Clear Logs",
            },
            {
                image: ChangeTagIcon,
                title: "Change Tag",
            },
        ];

        const firmware = row.row.original.firmware.split('.')
        const firmwareMajor = firmware[0]
        const firmwareMinor = firmware[1]
        const DeviceType = row.row.original.type;
        const isOnline = row.row.original.online == "YES" ? true : false;
        const deviceDetails = JSON.parse(row.row.original.deviceDetails);
        const camectIntegationType = deviceDetails.camectIntegrationType;

        if (companyName == "IPIO") {
            items.splice(3, 0, {
                image: ChangeOwnerIcon,
                title: "Change Owner",
            });
        }

        // Conditionally push the "Change Owner" object based on firmware version and device type
        // if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
        //     items.splice(4, 0, {
        //         image: camectIntegationType == 2 ? ChangeCamectIntergrationTypeIcon_On : ChangeCamectIntergrationTypeIcon_Off,
        //         title: "Change Camect Integration Type",
        //     },);
        // }

        if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
            items.splice(5, 0, {
                image: CopyDeviceSettingsIcon,
                title: "Copy site settings",
            });
        }

        if (isOnline) {
            items.splice(6, 0, {
                image: RebootDeviceIcon,
                title: "Reboot Site",
            },
            {
                image: ResetDeviceIcon,
                title: "Reset Site",
            });
        }

        const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
        const suspend = row.row.original.status == "suspended" ? "available" : "suspended";

        return <DropdownList
            item={row.row.id}
            icon={SettingsCog}
            onClick={handleSettingsClick}
            ifSettingsCogIsClicked={ifSettingsCogIsClicked}
            ref={dropdownRef}
            title={"Actions"}
            className={classes.container}
            onUserActionClick={(userAction) =>
                UserActionHandler(
                    userAction,
                    suspend,
                    row.row.original.id,
                    row.row.original.uuid,
                    userID,
                    row.row.original.name,
                    row.row.original.key,
                    camectIntegationType,
                    row.row.original.tag, 
                    row.row.original, 
                    deviceDetails
                )
            }
            listItems={items}
        />
      },
    }
  ];

  let tableData = SiteDevices;

  return (
    <>
      <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
        {modalComponent}
      </Modal>
      <div className={`${classes.table} ${classes.center} `}>
        <ListCard secondaryClassName={classes["Card-container"]}>
          <div className={classes["tableCardLayoutUI-wrapper"]} style={{padding:"0px 10px 0px 10px"}}>
            <Suspense fallback={
                <div className={classes["loadingSpinner-container"]}>
                    <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                </div>}>

                <Await resolve={SiteDevices}>
                    {(loadedtableData) =>
                        <BasicTable data={loadedtableData} columns={tablecolumns} filtering={filtering} rowClick={false} ShowPagination={false} PageSize={50} paginationOptions={[25, 50, 100]} showFilterButton={false} />
                    }
                </Await>
            </Suspense>
            <br></br>
            <br></br>
            <GridPaginationControls 
                pageIndex={pageFetchedIndex}
                nextPage={HandleNextPage}
                previousPage={HandlePreviousPage}
                setPageIndex={HandleSetPageIndex}
                pageCount={totalPages}
                setPageSize={HandlePageSize}
                PageSize={pageSize}
                totalDevices={totalDevices}
                setTotalDevices={setTotalDevices}
            />
          </div>
        </ListCard>
      </div>
    </>
  )
}

export default DevicesList;