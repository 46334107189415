import React, { useState, useRef, useEffect } from 'react'

import classes from "./SingleDeviceUsersTableLayout.module.css"

import swapSettings from "../../../assets/portalAssets/Swap arrow.png";
import RemoveUser from "../../../assets/portalAssets/trash-bin.png";

import Pill_Widget from '../Widgets/Pill_Widget';
import TaskTable from './TaskTable'
import { DateTime } from 'luxon'
import Verified_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/__Tick On.svg";
import NotVerified_Icon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/__Tick Off.svg";
import DropdownList from "../../UI/Cards/DropdownList";
import SettingsCog from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Admin - Settings.svg";
import InviteUserForm from "../../forms/InviteUserForm";
import Button1 from "../../UI/Button/Button1";

import ComponentCard from "../../UI/Cards/ComponentCard/ComponentCard";
import Input from '../../CustomDropDown/Input/Input';

import { changeRole, removeUser, inviteUser } from "../../../Api Methods/Api";
import { useLoaderData, useNavigate } from 'react-router-dom';
import SearchBar from '../../PowerSearch/SearchBar';
import BasicTable from '../../Table/Table/Table';
import { authActions } from '../../../store/auth-Slice';
import { useDispatch, useSelector } from 'react-redux';
import Input1 from '../Inputs/Input1';
import CopyUserToAnotherDeviceForm from '../../forms/CopyUserToAnotherDeviceForm';
import SearchbarSmall from '../Inputs/SearchbarSmall';
import ExportExcel from '../../ExcelExport/ExcelExport';
import UseDateFormat from '../../hooks/UseDateFormat';

const SingleDeviceUsersTableLayout = ({ SiteDeviceUserList, setModalTitle, setModalComponent, deviceTag, apiData, deviceId, userID, userRole, userEmail, DeviceName, HandleShowModal, HandleCloseModal, DeviceUUID, companyName, HandleDeviceUserList }) => {

    // -- Controls the settingCog wheel -------------------------------------- 
    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);

    const handleSettingsClick = (rowId) => {
        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
            setOpenDropdownRowId(null);
        } else {
            // Otherwise, open the clicked dropdown
            setOpenDropdownRowId(rowId);
        }
    };
    // -----------------------------------------------------------------------

    //------------------ handling the closing of the setting cog wheel drop down by clicking else where -----------------------------------

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Clicked outside the dropdown menu, so close it
                setOpenDropdownRowId(null);
            }
        };

        // Add event listener to listen for clicks outside the dropdown menu
        document.addEventListener('click', handleClickOutside);

        return () => {
            // Cleanup: Remove event listener when component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    //------------------------------------------------------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const HandleUipiodOnClick = async (apiData, UserID, fullName, companyName) => {
        sessionStorage.setItem('ClickedUserID', UserID);
        sessionStorage.setItem('ClickedUserFullName', fullName);

        dispatch(authActions.setClickedUserDetails({ UserID, fullName }))

        navigate("/Dashboard/Users/UserSites");
    }

    const columns = [
        {

            header: "Name",
            accessorKey: 'fullName',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
                        {row.row.original.fullName}
                    </div>
                )
            }
            // cell: (props) => <p> {props.getValue()}</p>
        },
        {

            header: "Email",
            accessorKey: 'email',
            cell: (row) => {
                return (
                    <div onClick={() => HandleUipiodOnClick(apiData, row.row.original.id, row.row.original.fullName, companyName)} style={{ cursor: "pointer", width: "fit-content" }}>
                        {row.row.original.email}
                    </div>
                )
            }
        },
        {
            header: "Role",
            accessorKey: 'role',
            cell: (props) => {
                let role = props.getValue() ? (props.getValue().charAt(0).toUpperCase() + props.getValue().slice(1)) : "";
                let installer = props.row.original.portalAccess;
                return (
                    <div style={{ display: "flex", gap: "10px" }}>
                        <Pill_Widget boolean={role == "User"} PillColor_IfTrue={"#d5d5d5"} PillColor_IfFalse={"#1e82d4"} text_IfTrue={"User"} text_IfFalse={"Manager"} textColour_IfTrue={"#424242"} textColour_IfFalse={"rgb(255, 255, 255)"} />
                        {installer != "" && <Pill_Widget boolean={installer} PillColor_IfTrue={"black"} PillColor_IfFalse={"none"} text_IfTrue={"Installer"} text_IfFalse={""} />}
                    </div>)
            }
        },
        // {header: "Account Added on", accessorFn: row => `${row.invitedBy} ${row.created}`},
        {
            header: "InvitedBy",
            accessorKey: 'invitedBy',
            cell: (row) => {
                let invitedBy = row.row.original.invitedBy;
                if (invitedBy == "") {
                    return "Added device using UIPIOID";
                }
                return invitedBy
            }
        },
        {
            header: "Account Created",
            accessorKey: 'created',
            // cell: (props) => <p> {props.getValue()}</p>
            cell: info =>
                DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED)
        },
        {

            header: "Verified",
            accessorKey: 'verified',
            cell: (row) => {
                let verified_icon = null;
                if (row.row.original.verified == "yes") {
                    verified_icon = Verified_Icon;
                } else {
                    verified_icon = "";
                }
                return (
                    <>
                        <img className={classes["VerifiedCell_image"]} src={verified_icon || ""} />
                    </>
                )
            }
        },
        // {
        //     id: 'actions',
        //     cell: (row) => {
        //         const personName = row.row.original.fullName;
        //         const personEmail = row.row.original.email;
        //         return <input type="checkbox" id="scales" name="scales" unchecked onChange={() => console.log(personName, personEmail)} />
        //     }
        // },
    ]

    const showHiddenFeatures = (companyName == "IPIO") && userRole === "TechSupport";

    if (showHiddenFeatures) {
        columns.push({
            id: 'actions',
            cell: (row) => {
                const items = [
                    {
                        image: swapSettings,
                        "title": "Change users role"
                    },
                    {
                        image: RemoveUser,
                        "title": "Remove User"
                    },

                ];
                const ifSettingsCogIsClicked = openDropdownRowId === row.row.id
                return (
                    <DropdownList
                        item={row.row.id}
                        icon={SettingsCog}
                        onClick={handleSettingsClick}
                        ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                        ref={dropdownRef}
                        title={"Actions"}
                        className={classes.container}
                        onUserActionClick={(userAction) =>
                            UserActionHandler(
                                userAction,
                                row.row.original.role,
                                row.row.original.id
                            )}
                        listItems={items} />
                )
            }
        });
    }

    const HandleInviteUser = () => {
        setModalTitle("Invite User to " + deviceTag)
        setModalComponent(
            <InviteUserForm apiData={apiData} DeviceId={deviceId} userID={userID} userRole={userRole} userEmail={userEmail} DeviceName={DeviceName} label={"Please enter new user details below"} dispatch={dispatch} HandleCloseModal={HandleCloseModal} HandleDeviceUserList={HandleDeviceUserList} />
        )
        HandleShowModal()
        // inviteUser(apiData, DeviceId, userID, userRole, email, deviceName)
    }

    const HandleCopyUsersToAnoterDevice = () => {
        setModalTitle("Copy users to another site")
        setModalComponent(<CopyUserToAnotherDeviceForm label={"Please enter the UIPIOID & name of the site below"} submitButtonText={"Copy"} processFunctionParamaters={{ apiData, deviceId, userID, DeviceName }} HandleCloseModal={setOpenDropdownRowId} dispatch={dispatch} />)
        HandleShowModal()
    }

    const UserActionHandler = (userAction, role, userIDToChange) => {
        if (role == "manager") {
            role = "user"
        } else if (role = "user") {
            role = "manager";
        }

        switch (userAction) {
            case 'Change users role':
                const ChangeRole = async () => {


                    const responseData = await changeRole(apiData, deviceId, userID, role, userIDToChange, DeviceUUID, dispatch);


                    if (responseData.success) {
                        setOpenDropdownRowId(null);
                        HandleDeviceUserList();
                    }


                }
                ChangeRole();
                break;
            case 'Remove User':

                removeUser(apiData, deviceId, DeviceUUID, userID, userIDToChange, dispatch).then((responseData) => {
                    if (responseData) {

                        //hide drop down menu
                        setOpenDropdownRowId(null);
                        //fetch users
                        HandleDeviceUserList();
                    }
                })
                break;
            default:
                console.warn('Invalid option:', userAction);
        }

        // console.log(DeviceID, userID, role, userIDToChange, uuid);
    }

    const [filtering, setFiltering] = useState('');

    const singleDeviceHeaderData = useSelector(state => state.service.singleDeviceHeaderData);
    const key = singleDeviceHeaderData.key;

    const [exportData, setExportData] = useState();

    // useEffect(() => {
    //     if (SiteDeviceUserList.data != [] && filtering == "") {
    //         const tempExportData = SiteDeviceUserList.data.map((user) => {
    //             const newObj = {
    //                 "Name": user.fullName,
    //                 "Email": user.email,
    //                 "Role": user.portalAccess != "" ? user.role + " Installer" : user.role,
    //                 "Invited By": user.invitedBy,
    //                 "Last Logged In": UseDateFormat(user.lastLoggedIn),
    //                 "Account Created": UseDateFormat(user.created)
    //             }
    //             return newObj
    //         })
    //         setExportData(tempExportData);
    //     }
    // }, [SiteDeviceUserList, filtering])

    const exportFormatFunction = (event) => {
        return {
            "Name": event.fullName,
            "Email": event.email,
            "Role": event.portalAccess != "" ? event.role + " Installer" : event.role,
            "Invited By": event.invitedBy,
            "Last Logged In": UseDateFormat(event.lastLoggedIn),
            "Account Created": UseDateFormat(event.created)
        };
    };

    // {
    //     fullName: 'Sean ESFX Developer',
    //     email: 's.duffy@eyespyfx.com',
    //     role: 'manager',
    //     id: 1645,
    //     verified: 'yes',
    //     invitedBy: 'Sean ESFX Developer',
    //     created: '2019-04-02T09:38:11.000Z',
    //     lastLoggedIn: '2024-07-10T10:21:02.000Z',
    //     portalAccess: 'EyeSpyFX'
    //   }

    return (
        <ComponentCard className={classes["table-container"]}>
            <div className={classes["button-container"]}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                    {/* <SearchbarSmall
                        type="text"
                        placeholder={"Search Device User..."}
                        value={filtering}
                        onChange={(e) => setFiltering(e.target.value)}
                    /> */}
                    <SearchbarSmall
                        type="text"
                        placeholder={"Search Users..."}
                        value={filtering}
                        onChange={(e) => setFiltering(e.target.value)}
                        onClear={() => setFiltering("")}
                    />
                    {/* <Button1 onClick={() => setFiltering("")}>Clear</Button1> */}
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                    {showHiddenFeatures && <div style={{ display: "flex", gap: "20px" }}>
                        <Button1 onClick={HandleInviteUser}> Invite User</Button1>
                        <Button1 onClick={HandleCopyUsersToAnoterDevice}> Copy Users to another site</Button1>

                    </div>}
                    <ExportExcel
                        excelData={exportData}
                        fileName={"IPIO Site: " + key + " Users"}
                        title={"IPIO Portal-Users: " + key}
                        colWidths={
                            [
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 15 },
                                { wch: 30 },
                                { wch: 25 },
                                { wch: 25 },
                            ]

                        }
                    />
                </div>
            </div>
            <BasicTable data={SiteDeviceUserList.data} columns={columns} filtering={filtering} rowClick={false} ShowPagination={true} PageSize={20} paginationOptions={[10, 20, 30]} setExportData={setExportData} exportFormatFunction={exportFormatFunction} />
            {/* <TaskTable data={deviceUsersList} columns={columns} filtering={filtering} /> */}
        </ComponentCard >
    )
}

export default SingleDeviceUsersTableLayout