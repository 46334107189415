import React, { Suspense, useMemo, useState, useEffect, useRef } from 'react'
import { addSitesToGroup, getGroupSites } from '../../Api Methods/Api';
import { Await, defer, Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classes from './GroupSites.module.css'
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import DataManager from '../../hooks/DataManager';
import Button1 from '../../components/UI/Button/Button1';
import Modal from '../../components/Modal/Modal';
import DropDownForm from '../../components/forms/DropdownCreateGroup';

const GroupSites = () => {

    const [groupSites, setGroupSites] = useState()
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const [searchQuery, setSearchQuery] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [groupMode, setGroupMode] = useState(false);
    const dataManager = new DataManager();
    const groupName = dataManager.getItem('groupClicked');
    const navigate = useNavigate();
    const apiData = {
        token,
        client
    }
    const dispatch = useDispatch();

    const [buttonText, setButtonText] = useState('Remove sites')
    const checkedItems = useState(dataManager.selectedSites.value || []);
    const [selectedGroup, setSelectedGroup] = useState('');

    const [modalComponent, setModalComponent] = useState();
    const [name, setName] = useState();
    const [title, setTitle] = useState('');
    const [update, setUpdate] = useState(0);

    const HandleShowModal = () => {
        ModalDialog.current.open();
    };

    const HandleCloseModal = () => {
        ModalDialog.current.close();

    };

    const ModalDialog = useRef();


    const location = useLocation();
    console.log('Current path:', location.pathname);
    const isGroupUsersPage = location.pathname.includes('GroupUsers'); // Check if on GroupUsers page

    let data;

    useEffect(() => {
        const subscription = dataManager.selectedSites.subscribe((sites) => {
            setButtonText(sites.length > 0 ? 'Choose group' : 'Cancel');
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const HandleGetGroupSites = async () => {
            data = await getGroupSites(apiData, groupName)
            setGroupSites(data)
            console.log(data);
        }

        HandleGetGroupSites()
    }, [update])

    const handleFilterLogic = async (data, searchQuery) => {
        const Data = await data;
        console.log(searchQuery)
        console.log(dataManager.getItem('searchQuery'))
        if (searchQuery === "" && (dataManager.getItem('searchQuery') !== "")){
            console.log("here")
            setGroupSites(Data)
            setUpdate(update+1)
        } else {
            const filteredResults = Data.filter(result => {
                const query = searchQuery.toLowerCase();
                const nameMatch = result.name.toLowerCase().includes(query);
                const keyMatch = result.key.toLowerCase().includes(query);
                const tagMatch = result.tag.toLowerCase().includes(query);
                return nameMatch || keyMatch || tagMatch;
            });

            setGroupSites(filteredResults)
        }
    };

    const addSites = () => {
        dataManager.setItem('isGroupMode', true);
        navigate('/Dashboard/');
    }

    const transferSites = async () => {

        //use 'selectedGroup' to find the ID of that group
        console.log(selectedGroup);
        const groups = JSON.parse(sessionStorage.getItem('groups'));
        const group = groups.find(g => g.name === dataManager.getItem('selectedGroup'));

        if (group) {
            console.log("ID:", group.id);
            let body = {
                groupID: group.id,
                groupName: group.name,
                groupDevices: dataManager.selectedSites.value,
            }

            const response = await addSitesToGroup(apiData, body, dispatch);
            if (response) {
                dataManager.setItem('isGroupMode', false);
                dataManager.setSelectedSites([]);
                setGroupMode(false);
                setUpdate(update + 1);
                HandleCloseModal();
            } else {
                //error
                console.log("ERROR")
            }
        } else {
            //ID not found 
            console.log("ERROR: GROUP NOT FOUND")

        }
    }

    const chooseNewGroup = () => {
        const groups = JSON.parse(sessionStorage.getItem('groups'));
        const groupNames = groups.map(group => group.name);
        console.log(groupNames)
        setTitle("Transfer sites")
        setModalComponent(
            <div>
                <div>
                    <DropDownForm label={"Please select the new group below"} dropDownOptions={groupNames} setSelectedGroup={setSelectedGroup} />
                </div>
                <br></br>
                <Button1 onClick={transferSites}>Transfer</Button1>
            </div>
        )

        HandleShowModal();
    }

    const editSites = () => {
        dataManager.setItem('isGroupMode', !groupMode);
        setGroupMode(!groupMode);

        if (buttonText == "Choose group") {
            //show modal for group transfer
            chooseNewGroup();
        }
    }

    useEffect(() => {
        if (groupMode) {
            if (dataManager.getItem('selectedSites').length > 0) {
                setButtonText('Choose group')
            } else {
                setButtonText('Cancel')
            }
        } else {
            setButtonText('Remove sites')
        }
    }, [groupMode, checkedItems])

    const viewUsers = () => {
        navigate('/Dashboard/Groups/GroupSites/GroupUsers/');
    }

    return (
        <>
            <Modal ref={ModalDialog} title={title} modalName={""} HandleShowModal={HandleShowModal}>
                {modalComponent}
            </Modal>

            <div className={classes['activtyPage_container']}>
                <div style={{ marginLeft: "3px", color: "#7f7f7f" }}>
                    <h2>{groupName}</h2>
                </div>

                {!isGroupUsersPage && (
                    <>
                        {groupSites && <FilterUsersDeviceSearch Devices={groupSites} searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Sites..."} setIsLoading={setIsLoading} setFilteredResults={setGroupSites} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />}

                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: "0px", marginTop: "0px", gap: "10px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button1 style={{ height: "50px", width: "110px", fontSize: "16px" }} onClick={addSites}>Add sites</Button1>
                                <Button1 style={{ height: "50px", width: "160px", fontSize: "16px" }} onClick={editSites}>{buttonText}</Button1>
                                <Button1 style={{ height: "50px", width: "110px", fontSize: "16px" }} onClick={viewUsers}>View Users</Button1>
                            </div>
                        </div>

                        <Suspense fallback={
                            <div className={classes["loadingSpinner-container"]}>
                                <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
                            </div>}>

                            <Await resolve={groupSites}>
                                {(loadedUserDevices) => (
                                    <div style={{ display: "flex", width: "100%" }}>
                                        {groupSites ? (
                                            loadedUserDevices && loadedUserDevices.length > 0 ? (
                                                <DevicesGrid
                                                    data={loadedUserDevices}
                                                    pageFetchedIndex={1}
                                                    isUsersSites={true}
                                                    groupMode={groupMode}
                                                />
                                            ) : (
                                                <p>{`No sites found.`}</p>
                                            )
                                        ) : (
                                            <p>Loading sites...</p>
                                        )}
                                    </div>
                                )}
                            </Await>
                        </Suspense>
                    </>
                )}
                <Outlet />
            </div>
        </>
    )
}

export default GroupSites

export async function loader() {
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const apiData = {
        token,
        client
    }

    return defer({
        groupSites: getGroupSites(apiData, "IPIO")
    })
}